import React from "react";
import { createBrowserRouter } from "react-router-dom";
import * as dashboardRoot from "../routes/dashboard/root";
import * as campaignRoot from "../routes/dashboard/campaigns/root";
import * as templateRoot from "../routes/dashboard/templates/root";
import * as templateDetail from "../routes/dashboard/templates/detail";
import * as templateCreate from "../routes/dashboard/templates/create";
import * as ErrorRoot from "../routes/error";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <dashboardRoot.Template />,
        children: [
            {
                path: "/templates",
                element: <templateRoot.Template />,
                loader: templateRoot.loader,
            },
            {
                path: "/templates/:id",
                element: <templateDetail.Template />,
                loader: templateDetail.loader,
            },
            {
                path: "/templates/create",
                element: <templateCreate.Template />,
                loader: templateCreate.loader,
            },
            {
                path: "/campaigns",
                element: <campaignRoot.Template />,
                loader: campaignRoot.loader,
            },
        ],
    },
    {
        path: "*",
        element: <ErrorRoot.Template />,
    },
]);

import React from "react";
import { Link, useLoaderData } from "react-router-dom";

export const TemplateRoot = () => {
    const data = useLoaderData();

    return (
        <div>
            TemplateRoot <pre>{JSON.stringify(data)}</pre>
            <Link to="/templates/create">Aanmaken..</Link>
        </div>
    );
};

export default TemplateRoot;
